<template>
  <div>
    <promotion-edit
      v-if="page === 'promotion'"
      :promotion="promotion"
    ></promotion-edit>
    <div class="text-center mt-5" v-else-if="page === 'loading'">
      <v-progress-circular
        indeterminate
        color="secondary"
        width="4"
        size="60"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import PromotionEdit from "./../../components/promotions/edit";
import { mapActions } from "vuex";
export default {
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      try {
        const response = await vm.request({
          url: "api/v1/promotions",
          params: {
            id: to.params.id,
          },
          messages: 500
        });
        const promotion = response.data.items[0];
        if (!promotion) {
          vm.page = "error";
          vm.notify({ message: vm.$t("promotions.errors.not_found") });
        } else {
          vm.promotion = promotion;
          vm.page = 'promotion'
          vm.title = vm.$t('promotions.edit.title2', { promotion: promotion.name })
        }
      } catch (error) {
        // empty
      }
    });
  },
  created() {
    this.setTitle();
  },
  data: () => ({
    title: null,
    reseller: {},
    page: "loading",
  }),
  metaInfo() {
    return {
      title: this.title,
    };
  },
  methods: {
    setTitle() {
      this.title = this.$t("promotions.edit.title");
    },
    ...mapActions({ notify: "notification/notify", request: "request" }),
  },
  components: {
    PromotionEdit,
  },
};
</script>